import React, { useContext, useEffect, useState } from "react"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updateSoccerStats,
  addSoccerStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"

function SoccerStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    opponent_team: "",
    opponent_team_custom: "",
    result: "",
    points_scored: "",
    assists: 0,
    goals: 0,
    shots: 0,
    interceptions: 0,
    tackles: 0,
    shots_on_target: 0,
    passes: 0,
    fouls_committed: 0,
    passes_completed: 0,
    fouls_suffered: 0,
    yellow_cards: 0,
    red_cards: 0,
    dribbles: 0,
    successful_dribbles: 0,
    key_passes: 0,
    aerial_duels_won: 0,
    successful_tackles: 0,
    saves: 0,
    aerial_duels: 0,
    loading: false,
    teams: [],
    userProfileData: null
  })
  const {
    date_of_game,
    opponent_team,
    opponent_team_custom,
    result,
    assists,
    goals,
    shots,
    tackles,
    interceptions,
    shots_on_target,
    passes,
    fouls_committed,
    passes_completed,
    fouls_suffered,
    red_cards,
    yellow_cards,
    dribbles,
    successful_dribbles,
    key_passes,
    loading,
    teams,
    aerial_duels,
    aerial_duels_won,
    saves,
    successful_tackles,
    goals_against,
    clean_sheets,
    penalties_faced,
    penalties_saved,
    userProfileData
  } = state

  const points_scored_calculated =
    Number(fouls_suffered || 0) * 2 +
    Number(red_cards || 0) * 3 +
    Number(successful_dribbles || 0) * 1

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.opponent_team)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.opponent_team)?.team_name)
      }
      handleChange("opponent_team", statData?.opponent_team)
      handleChange("opponent_team_custom", statData?.opponent_team_custom)
      handleChange("points_scored_calculated", statData?.points_scored_calculated)
      handleChange("assists", statData?.assists)
      handleChange("goals", statData?.goals)
      handleChange("shots", statData?.shots)
      handleChange("shots_on_target", statData?.shots_on_target)
      handleChange("passes", statData?.passes)
      handleChange("fouls_committed", statData?.fouls_committed)
      handleChange("passes_completed", statData?.passes_completed)
      handleChange("fouls_suffered", statData?.fouls_suffered)
      handleChange("yellow_cards", statData?.yellow_cards)
      handleChange("red_cards", statData?.red_cards)
      handleChange("dribbles", statData?.dribbles)
      handleChange("successful_dribbles", statData?.successful_dribbles)
      handleChange("key_passes", statData?.key_passes)
      handleChange("interceptions", statData?.interceptions)
      handleChange("tackles", statData?.tackles)
      handleChange("aerial_duels_won", statData?.aerial_duels_won)
      handleChange("successful_tackles", statData?.successful_tackles)
      handleChange("saves", statData?.saves)
      handleChange("goals_against", statData?.goals_against)
      handleChange("clean_sheets", statData?.clean_sheets)
      handleChange("penalties_faced", statData?.penalties_faced)
      handleChange("penalties_saved", statData?.penalties_saved)
      handleChange("aerial_duels", statData?.aerial_duels)
    } else {
      handleChange("date_of_game", "")
      handleChange("opponent_team", "")
      handleChange("result", "")
      handleChange("opponent_team_custom", "")
      handleChange("assists", 0)
      handleChange("goals", 0)
      handleChange("shots", 0)
      handleChange("interceptions", 0)
      handleChange("tackles", 0)
      handleChange("shots_on_target", 0)
      handleChange("passes", 0)
      handleChange("fouls_committed", 0)
      handleChange("passes_completed", 0)
      handleChange("points_scored", 0)
      handleChange("yellow_cards", 0)
      handleChange("fouls_suffered", 0)
      handleChange("red_cards", 0)
      handleChange("dribbles", 0)
      handleChange("successful_dribbles", 0)
      handleChange("aerial_duels_won", 0)
      handleChange("successful_tackles", 0)
      handleChange("aerial_duels", 0)
      handleChange("saves", 0)
      handleChange("goals_against", 0)
      handleChange("clean_sheets", 0)
      handleChange("penalties_faced", 0)
      handleChange("penalties_saved", 0)
      setDropDownText("")
      handleChange("key_passes", 0)
    }
  }, [statData, visible, teams])

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0
        const payload = {
          date_of_game,
          opponent_team,
          opponent_team_custom,
          points_scored: points_scored_calculated,
          assists: assistsValue,
          goals,
          shots,
          interceptions,
          tackles,
          shots_on_target,
          passes,
          fouls_committed,
          passes_completed,
          fouls_suffered,
          yellow_cards,
          red_cards,
          dribbles,
          successful_dribbles,
          key_passes,
          successful_tackles,
          aerial_duels_won,
          saves,
          goals_against,
          clean_sheets,
          penalties_faced,
          penalties_saved,
          aerial_duels
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addSoccerStats(payload, token)
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("opponent_team", "")
        handleChange("result", "")
        handleChange("opponent_team_custom", "")
        handleChange("assists", 0)
        handleChange("goals", 0)
        handleChange("shots", 0)
        handleChange("interceptions", 0)
        handleChange("tackles", 0)
        handleChange("shots_on_target", 0)
        handleChange("passes", 0)
        handleChange("fouls_committed", 0)
        handleChange("passes_completed", 0)
        handleChange("points_scored", 0)
        handleChange("yellow_cards", 0)
        handleChange("fouls_suffered", 0)
        handleChange("red_cards", 0)
        handleChange("dribbles", 0)
        handleChange("successful_dribbles", 0)
        handleChange("aerial_duels_won", 0)
        handleChange("successful_tackles", 0)
        handleChange("saves", 0)
        handleChange("aerial_duels", 0)
        handleChange("goals_against", 0)
        handleChange("clean_sheets", 0)
        handleChange("penalties_faced", 0)
        handleChange("penalties_saved", 0)
        setDropDownText("")
        handleChange("key_passes", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0

        if (organization) {
          const payload = {
            date_of_game,
            opponent_team,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            opponent_team,
            opponent_team_custom,
            points_scored: points_scored_calculated,
            assists: assistsValue,
            goals,
            shots,
            interceptions,
            tackles,
            shots_on_target,
            passes,
            fouls_committed,
            passes_completed,
            fouls_suffered,
            yellow_cards,
            red_cards,
            dribbles,
            successful_dribbles,
            key_passes,
            aerial_duels_won,
            successful_tackles,
            saves,
            goals_against,
            clean_sheets,
            penalties_faced,
            penalties_saved,
            aerial_duels
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updateSoccerStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("opponent_team", "")
        handleChange("result", "")
        handleChange("opponent_team_custom", "")
        handleChange("assists", 0)
        handleChange("goals", 0)
        handleChange("shots", 0)
        handleChange("interceptions", 0)
        handleChange("tackles", 0)
        handleChange("shots_on_target", 0)
        handleChange("passes", 0)
        handleChange("fouls_committed", 0)
        handleChange("passes_completed", 0)
        handleChange("points_scored", 0)
        handleChange("yellow_cards", 0)
        handleChange("fouls_suffered", 0)
        handleChange("red_cards", 0)
        handleChange("dribbles", 0)
        handleChange("successful_dribbles", 0)
        handleChange("aerial_duels_won", 0)
        handleChange("successful_tackles", 0)
        handleChange("saves", 0)
        handleChange("goals_against", 0)
        handleChange("clean_sheets", 0)
        handleChange("penalties_faced", 0)
        handleChange("penalties_saved", 0)
        handleChange("aerial_duels", 0)
        setDropDownText("")
        handleChange("key_passes", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const positionName = userProfileData?.player?.first_position_play?.name
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text={"Team Played"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onChange={(event, newValue) => {
                handleChange("opponent_team_custom", "")
                handleChange(
                  "opponent_team",
                  teams[event?.target?.dataset?.optionIndex]?.id
                )
                setDropDownText(newValue)
              }}
              inputValue={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onInputChange={(event, newInputValue) => {
                handleChange("opponent_team_custom", newInputValue)
                handleChange("opponent_team", "")
              }}
              options={teams?.map(option => option.team_name)}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                    // Add your desired z-index value here
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Goals"}
              onChange={handleChange}
              value={goals}
              name={"goals"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Assists"}
              onChange={handleChange}
              value={assists}
              name={"assists"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Shots"}
              onChange={handleChange}
              value={shots}
              name={"shots"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Shots On Target"}
              onChange={handleChange}
              value={shots_on_target}
              name={"shots_on_target"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passes"}
              onChange={handleChange}
              value={passes}
              name={"passes"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Passes Completed"}
              onChange={handleChange}
              value={passes_completed}
              name={"passes_completed"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Fouls Committed"}
              onChange={handleChange}
              value={fouls_committed}
              name={"fouls_committed"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Fouls Suffered"}
              onChange={handleChange}
              value={fouls_suffered}
              name={"fouls_suffered"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Yellow Cards"}
              onChange={handleChange}
              value={yellow_cards}
              name={"yellow_cards"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Red Cards"}
              onChange={handleChange}
              value={red_cards}
              name={"red_cards"}
            />
          </Grid>
          {
            positionName === "Forward" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Dribbles"}
                  onChange={handleChange}
                  value={dribbles}
                  name={"dribbles"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Successful Dribbles"}
                  onChange={handleChange}
                  value={successful_dribbles}
                  name={"successful_dribbles"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Midfielder" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Key Passes"}
                  onChange={handleChange}
                  value={key_passes}
                  name={"key_passes"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Interceptions"}
                  onChange={handleChange}
                  value={interceptions}
                  name={"interceptions"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Tackles"}
                  onChange={handleChange}
                  value={tackles}
                  name={"tackles"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Successful Tackles"}
                  onChange={handleChange}
                  value={successful_tackles}
                  name={"successful_tackles"}
                />
              </Grid>
            </>
          }
          {
            positionName === "Defender" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Interceptions"}
                  onChange={handleChange}
                  value={interceptions}
                  name={"interceptions"}
                />
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Tackles"}
                  onChange={handleChange}
                  value={tackles}
                  name={"tackles"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Successful Tackles"}
                  onChange={handleChange}
                  value={successful_tackles}
                  name={"successful_tackles"}
                />
              </Grid> */}
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Aerial Duels"}
                  onChange={handleChange}
                  value={aerial_duels}
                  name={"aerial_duels"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Aerial Duels Won"}
                  onChange={handleChange}
                  value={aerial_duels_won}
                  name={"aerial_duels_won"}
                />
              </Grid>


            </>
          }
          {
            positionName === "Goalkeeper" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Saves"}
                  onChange={handleChange}
                  value={saves}
                  name={"saves"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Goals Against"}
                  onChange={handleChange}
                  value={goals_against}
                  name={"goals_against"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Clean Sheets"}
                  onChange={handleChange}
                  value={clean_sheets}
                  name={"clean_sheets"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Penalties Faced"}
                  onChange={handleChange}
                  value={penalties_faced}
                  name={"penalties_faced"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Penalties Saved"}
                  onChange={handleChange}
                  value={penalties_saved}
                  name={"penalties_saved"}
                />
              </Grid>
            </>
          }
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default SoccerStats
